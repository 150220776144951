import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

function createMarkup(markup) {
  return { __html: markup }
}

export default (props) => {
  const {
    pageTitle,
    subtitle,
    terms: { terms },
    pdf: {
      file: { url: pdfURL },
    },
  } = props.data.allContentfulPageTerms.nodes[0]
  const termsAndConditionsPDF = `https:${pdfURL}`

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4 pb-5">
          <PageSubtitle subtitle={subtitle} />
          <div className="row">
            <div className="col-md-3 align-self-end ml-auto text-right">
              <a
                className="btn btn-link text-decoration-none"
                href={termsAndConditionsPDF}
                target="_blank"
                rel="noopener noreferrer">
                <i className="far fa-file-pdf mr-2 font-size-5" />
                View as PDF
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div dangerouslySetInnerHTML={createMarkup(terms)}></div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulPageTerms {
      nodes {
        pageTitle
        subtitle
        terms {
          terms
        }
        pdf {
          file {
            url
          }
        }
      }
    }
  }
`
